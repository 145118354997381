<template>
	<div>
		<jy-query ref="form" :model="form">
			<jy-query-item label="开始时间:" prop="time">
				<el-date-picker v-model="form.stime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
			</jy-query-item>
			<jy-query-item label="结束时间:" prop="time">
				<el-date-picker v-model="form.etime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
			</jy-query-item>
			<jy-query-item prop="routeId" label="线路">
				<el-select v-model="form.routeId">
					<el-option v-for="one in routeOptions" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="驾驶员:" prop="routeId">
				<el-select v-model="form.driverId" placeholder="请选择驾驶员" v-loadmore="loadMore">
					<el-option v-for="item in driverOptions" :key="item.driverId" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
				<!-- <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('reset')">重置</el-button> -->
			</template>
		</jy-query>
		<jy-table max-height="595" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="operateDate" label="日期"></jy-table-column>
			<jy-table-column prop="orgNa" label="所属机构"></jy-table-column>
			<jy-table-column prop="route" label="线路"></jy-table-column>
			<jy-table-column prop="driver" label="驾驶员"></jy-table-column>
			<jy-table-column prop="cno" label="员工工号"></jy-table-column>
			<jy-table-column prop="times" label="刷卡次数"></jy-table-column>
			<jy-table-column prop="time" label="工作时长"></jy-table-column>
		</jy-table>
		<div class="total" v-show="isShow">
			<span>总计：</span>
			<span>打卡 4次</span>
			<span>准点2次</span>
			<span>迟到1次</span>
			<span>早退1次</span>
		</div>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			form: {
				stime: "",
				etime: "",
				routeId: "",
				driverId: ""
			},
			isShow: false,
			dataList: [],
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			routeOptions: [],
			driverOptions: []
		};
	},
	watch: {
		"form.driverId": function (val) {
			if (val != "") {
				this.isShow = true;
			} else {
				this.isShow = false;
			}
		}
	},
	async created() {
		await this.getRouteList();
		this.getDrivers();
		this.getList();
	},
	mixins: [btnMixins],
	methods: {
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			await this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		getList() {
			this.dataList = [
				{
					operateDate: "2022-04-11",
					orgNa: "泉州软件园",
					route: "901路",
					driver: "华晨",
					cno: "0311212",
					times: "3",
					time: 8
				},
				{
					operateDate: "2022-04-11",
					orgNa: "泉州软件园",
					route: "901路",
					driver: "白白",
					cno: "123456",
					times: "3",
					time: 8
				},
				{
					operateDate: "2022-04-11",
					orgNa: "泉州软件园",
					route: "901路",
					driver: "小红花",
					cno: "123622541515",
					times: "3",
					time: 8
				}
			];
			// let option = {
			//     ...this.form
			// }
			// if (option.time) {
			//     option.beginDate = option.time[0]
			//     option.endDate = option.time[1]
			// } else {
			//     option.beginDate = ''
			//     option.endDate = ''
			// }
			// let url = '/stattripmileagedaily/queryVehicleDailyTotal'
			// this.$http.post(url, option).then(res => {
			//         this.dataList = res.detail.list
			//         this.total = res.detail.total
			// })
		},
		getDrivers() {
			let option = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/base/driver/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				console.log(detail);
				let list = detail.list.map(item => {
					return {
						label: item.driverInfo.cName,
						value: item.driverInfo.driverId
					};
				});
				this.driverOptions = list;
			});
		},
		// 查询表格
		oncheck() {
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.routeId = "";
			this.form.stime = "";
			this.form.etime = "";
			this.form.driverId = "";
			this.pageIndex = 1;
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getList();
		},
		loadMore() {
			console.log(111);
			this.pageIndex += 1;
			let option = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/base/driver/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				if (detail.list.length > 0) {
					detail.list.map(item => {
						let list = {
							label: item.driverInfo.cName,
							value: item.driverInfo.driverId
						};
						this.driverOptions.push(list);
					});
				}
			});
		}
	}
};
</script>
<style scoped>
.total {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	height: 54px;
	background: inherit;
	background-color: rgba(215, 215, 215, 1);
	padding: 20px;
	font-size: initial;
}
</style>
